
import React,{useState} from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import styles from './List.module.css';
import { SectionUtility } from '../../utils/tools';
import { Services } from '../../services';
import Toast from 'react-bootstrap/Toast';
import HTMLReactParser from 'html-react-parser';
import { URLS } from '../../constants/config';


const formatDate = (date) => {
  const d = new Date(date)
  const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
  return d.toLocaleTimeString('en-us', options)
}

const ScoreList = ({
  list,
  setStudentInfo,
  setAssessmentInfo,
  handleShow,
  handleShowAssessment,
  setList,
  setScores,
  egraS3Json,
  sections

}) => {
//question is index+1 value from egra json
const getSectionLabel = (id, question) => {
  
  let details = SectionUtility.GetSectionDetails(id)
  let label = details?.display
  
  if (egraS3Json) {
    let egraSection = SectionUtility.GetEgra3Section(egraS3Json, id)

    if (SectionUtility.IsSectionListeningType(details) === true) {
      label = label + " [" + (egraSection.answers.length>0?egraSection.answers[question-1]:"") + "]"
      label = egraSection.answers.length>0?egraSection.answers[question-1]:""
    }
    else {
      label = label + " [" + egraSection.questions[question-1] + "]"
      label =egraSection.questions[question-1]
    }
    return label
  }
  return ""
}

const [showToast, setShowToast] = useState(false)
const [assessmentEnabled, setAssessmentEnabled] = React.useState(true);

const Toaster = ()=>{
  return <div className="toast-container position-fixed top-0 start-50 translate-middle-x">
  <Toast
    className="d-inline-block m-1"
    bg={"success"}
    show={showToast}
    delay={3000} autohide={true}
  >
    <Toast.Body style={{ color: "white" }}>
      Score saved.
    </Toast.Body>
  </Toast>
</div>
}

const SectionItems = ({section})=>{
  const recordings = [...list].filter((x)=>{ return x.sectionId===section.id})

  const handleSaveRowClick = (e)=>{
    const key = e.currentTarget.getAttribute('data-item')
    const audioUUID =e.currentTarget.getAttribute('data-audio-uuid')
    const elemScore = document.getElementById('score-'+audioUUID+"-"+key)
    const elemIsClear = document.getElementById('isclear-'+audioUUID+"-"+key)
    const elemTranscription = document.getElementById('transcription-'+audioUUID+"-"+key)

    const score =elemScore.value
    const transcription =elemTranscription.value      
    const isClear = elemIsClear.value
    Services.Exam.updateScore({
      datasetAudioUuid: [audioUUID],
      score: [score],
      transcription: [transcription],
      isClear:[isClear]
    })
      .then((response) => {
        const highlight = (el)=>{
          const color = "#dee2e6"
          const width = "1px"
          el.style.background = "#DAF7A6"
          el.style.borderWidth = "3px"
          setTimeout(() => {
            el.style.borderColor = color
            el.style.borderWidth = width
            el.style.background = "unset"
          }, 500);
        }
        highlight(elemScore)
        highlight(elemTranscription)
      })
      .catch((e) => {
        console.log("updateScore", e);
      });
  }


  return (
    <div>
      <h3 
      className='d-inline'
      style={{ float: "left", color: "white",marginRight:"2em" }}>
        {section?.value === "Onset Sound"
          ? "Phonemic Awareness"
          : section?.value} {recordings[0]?" ["+formatDate(recordings[0]?.createdAt)+"]":''}
      </h3>
      <button

        disabled = {Services.User.isTeacher() ? true : false}
        className='btn btn-primary'
        style={{float:"right"}}
        onClick={(e) => {
          const audioUUID = [];
          const scores = [];
          const transcriptions = [];
          const isclears = [];
          recordings.forEach(
            (
              {
                sectionId,
                userUuid,
                createdAt,
                datasetAudioUuid,
                studentName,
                schoolName,
                question,
                score,
                isClear,
                transcription,
              },
              index
            ) => {
              const elScore = document.getElementById(
                "score-" + datasetAudioUuid + "-" + index
              ).value;
              const elTranscription = document.getElementById(
                "transcription-" + datasetAudioUuid + "-" + index
              ).value;
              const elIsClear = document.getElementById(
                "isclear-" + datasetAudioUuid + "-" + index
              ).value;
              audioUUID.push(datasetAudioUuid);
              scores.push(elScore);
              transcriptions.push(elTranscription);
              isclears.push(elIsClear)
            }
          );

          Services.Exam.updateScore({
            datasetAudioUuid: audioUUID,
            score: scores,
            transcription: transcriptions,
            isClear:isclears
          }).then((response)=>{
            window.location.reload();
            console.log(response)
          })
        }}
      >
        Save All
      </button>
      <table className="table table-bordered border-primary table-sm">
        <thead>
          <tr>
            <th>SN</th>
            <th>Correct answer</th>
            <th style={{ width: "6em" }}>Score</th>
            <th style={{ width: "8em" }}>Audio</th>
            <th style={{ width: "20em" }}>Transcription</th>
            <th style={{ width: "5em" }}>Audio OK?</th>
            <th></th>
          </tr>
        </thead>
        <tbody style={{ verticalAlign: "middle" }}>
          {recordings.map(
            (
              {
                sectionId,
                userUuid,
                createdAt,
                datasetAudioUuid,
                studentName,
                schoolName,
                question,
                score,
                transcription,
                isClear,
                audioUrl
              },
              index
            ) => {
              return (
                <tr key={"row-" + index.toString()}>
                  <td>{index + 1}</td>
                  {/* <td>{formatDate(createdAt)}</td> */}
                  {/* <td>
                    <Link
                      onClick={() => {
                        setStudentInfo(userUuid).then(() => {
                          handleShow();
                        });
                      }}
                    >
                      {studentName}
                    </Link>
                  </td>
                  <td>{schoolName}</td> */}
                  <td>
                    {sectionId && question
                      ? HTMLReactParser(getSectionLabel(sectionId, question))
                      : ""}
                  </td>
                  <td>
                    <input
                      id={"score-" + datasetAudioUuid + "-" + index}
                      data-item={index}
                      type="text"
                      className="form-control"
                      defaultValue={score}
                      style={{ height: "40px" }}
                      // onChange={(e) => {
                      //   setList(list.map((item) => {
                      //     if (datasetAudioUuid === item.datasetAudioUuid)
                      //       item.score = e.currentTarget.value
                      //     return item
                      //   }))
                      // }}
                    />
                  </td>
                  <td>
                    <audio
                      controls
                      style={{
                        height: "1.5em",
                        width: "10em",
                        border: "1px solid white",
                        borderRadius: "50px",
                      }}
                      src={`${URLS.baseuri}/api/audio-play/${audioUrl}`}
                      id={"audio-" + sectionId + index.toString()}
                      // style={{ display: "none" }}
                    >
                      Your browser does not support the audio tag.
                    </audio>
                    {sectionId === 5 && <Link style={{
                      pointerEvents:assessmentEnabled ? 'auto': 'none',
                      color:assessmentEnabled ? '': 'gray',
                      textDecoration:'none',
                      
                    }}
                      onClick={() => {
                        setAssessmentEnabled(false)                        
                        setAssessmentInfo(datasetAudioUuid).then(() => {
                          setAssessmentEnabled(true)
                          handleShowAssessment();
                        });
                      }}
                    >
                      Assessment
                    </Link>}
                  </td>
                  <td>
                    {sectionId === 5 ? (
                      <>
                        <textarea
                          id={"transcription-" + datasetAudioUuid + "-" + index}
                          data-item={index}
                          className="form-control"
                          rows="10"
                          defaultValue={transcription.replace(
                            /(<([^>]+)>)/gi,
                            ""
                          )}
                          //onChange={handleChange}
                          // onChange={(e) => {
                          //   setList(list.map((item) => {
                          //     if (datasetAudioUuid === item.datasetAudioUuid)
                          //       item.transcription = e.currentTarget.value
                          //     return item
                          //   }))
                          // }}
                        ></textarea>
                      </>
                    ) : (
                      ""
                    )}
                    {sectionId !== 5 ? (
                      <>
                        <input
                          type="text"
                          className="form-control"
                          style={{ height: "40px" }}
                          id={"transcription-" + datasetAudioUuid + "-" + index}
                          data-item={index}
                          defaultValue={transcription}
                          // onChange={(e) => {
                          //   setList(list.map((item) => {
                          //     if (datasetAudioUuid === item.datasetAudioUuid)
                          //       item.transcription = e.currentTarget.value
                          //     return item
                          //   }))
                          // }}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    <select disabled = {Services.User.isTeacher() ? true : false}
                      className="form-control-sm"
                      id={"isclear-" + datasetAudioUuid + "-" + index}
                    >
                      {"yes" === isClear && (
                        <>
                          <option value="no">No</option>
                          <option value="yes" selected={"yes"}>
                            Yes
                          </option>
                        </>
                      )}

                      {"no" === isClear && (<>
                        <option value="no" selected={"yes"}>
                          No
                        </option>
                        <option value="yes">Yes</option></>
                      )}
                    </select>
                  </td>
                  <td>
                    <button
                      disabled = {Services.User.isTeacher() ? true : false}
                      className="btn btn-sm btn-outline-primary"
                      defaultValue={datasetAudioUuid}
                      data-audio-uuid={datasetAudioUuid}
                      data-item={index}
                      onClick={handleSaveRowClick}
                    >
                      Save
                    </button>
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </div>
  );
}

return(
  <>
    <Toaster />
    <h2 style={{ textAlign: "left", color: "white" }}>Breakdown</h2>
    {
      sections.map((section)=>{
        return <SectionItems section={section}/>
      })
    }
    </>
);
}
ScoreList.propTypes = {};

ScoreList.defaultProps = {};

export default ScoreList;
