import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import styles from "./School.module.css";
import { Services } from "../../services/index";
import { Link } from "react-router-dom";
const ModerateSchool = () => {
  const [formData, setFormData] = useState({
    school: "",
    students: "",
    email:"",
    password:"",
    language:"",
    message: "",
  });

  useEffect(() => {
    Services.Exam.getListOfSchools().then((data) => {
      if (Services.User.isTeacher()) {
        let match = data.find((x) => {
          return (
            x.schoolUuid === Services.User.getLocalStorageDetails()?.schoolUuid
          );
        });
        if (match) setList([match]);
      }
      if (Services.User.isModerator()) {
        setList(data);
      }
    });
  }, []);

  const Views = { ListOfSchool: "ListOfSchool", SchoolForm: "SchoolForm", ListOfStudents:"ListOfStudents" };
  const [result, setResult] = useState({ school: "", list: [] });
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [view, setView] = useState(Views.ListOfSchool);
  const [list, setList] = useState([]);
  const [students,setStudents] = useState([])
  const [teachers,setTeachers] = useState([])
  const StudentList = ()=>{
    return (
      <>
        <div className={styles.SchoolList}>
          <Table striped bordered hover size="sm">
            <thead style={{ textAlign: "center" }}>
              <tr>
                <th colSpan={14} style={{ textAlign: "right" }}>
                  {teachers.length>0 && <span style={{ float: "left" }}>
                    Teacher:{" "}
                    {teachers
                      .map((x) => {
                        return x.username;
                      })
                      .join(",")}
                  </span>}
                  
                  <Link
                    onClick={() => {
                      setView(Views.ListOfSchool);
                    }}
                  >
                    Back To School
                  </Link>{" "}
                  &nbsp;
                  {Services.User.isModerator() && 
                  <Link
                  onClick={() => {
                    if(teachers.length>0){
                      const payload = {
                        teacherUuid: teachers[0].userUuid,
                      };
                      Services.User.WholeClassPINReset(payload).then((response)=>{
                        setStudents(response)
                      })
                    }
                  }}
                >
                  Reset PIN
                </Link>                  
                  }

                </th>
              </tr>
              <tr>
                <th>#</th>
                <th>School</th>
                <th>School ID</th>
                <th>Student Username</th>
                <th>Student PIN</th>
                <th>Created On</th>
              </tr>
            </thead>
            <tbody style={{ verticalAlign: "middle", textAlign: "center" }}>
              {students.map((student, index) => {
                return (
                  <tr key={"row-" + index}>
                    <td>{index + 1}</td>
                    <td>{student?.School?.name}</td>
                    <td>{student?.School?.schoolPin}</td>
                    <td style={{ textAlign: "left" }}>
                      &nbsp;{student?.username}
                    </td>
                    <td>{student?.pin}</td>
                    <td>{new Date(student?.createdAt).toLocaleDateString()}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </>
    );
  }

  const SchoolList = () => {
    console.log(Services.User.isModerator())
    return (
      <>
        <div className={styles.SchoolList}>
          <Table striped bordered hover size="sm">
            <thead style={{ textAlign: "center" }}>
              {Services.User.isModerator() && (
                <tr>
                  <th colSpan={14} style={{ textAlign: "right" }}>
                    <button className="btn btn-primary" onClick={CreateNew}>
                      New School
                    </button>
                  </th>
                </tr>
              )}
              <tr>
                <th>#</th>
                <th>School</th>
                <th>Language</th>
                <th>Created On</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody style={{ verticalAlign: "middle", textAlign: "center" }}>
              {list.map((school, index) => {
                return (
                  <tr key={"row-" + index}>
                    <td>{index + 1}</td>
                    <td style={{ textAlign: "left" }}>&nbsp;{school?.name}</td>
                    <td>{school?.language}</td>
                    <td>{new Date(school?.createdAt).toLocaleDateString()}</td>
                    <td>
                      <Link
                        onClick={() => {
                          setView(Views.ListOfStudents);
                          Services.Exam.getListOfStudents(school?.schoolUuid)
                            .then((data) => {
                              setStudents(data);
                            })
                            .catch((e) => {
                              console.log(e);
                            });
                          Services.Exam.getListOfTeachers(school?.schoolUuid)
                            .then((data) => {
                              setTeachers(data);
                            })
                            .catch((e) => {
                              console.log(e);
                            });
                        }}
                      >
                        Students
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </>
    );
  };

  const CreateNew = () => {
    setResult({ ...result, list: [] });
    setBtnDisabled(false);
    setView(Views.SchoolForm);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setBtnDisabled(true);
    Services.User.BulkRegister({
      schoolName: formData?.school,
      no_of_student: formData?.students,
      teacherEmail:formData?.email,
      password:formData?.password,
      language:formData?.language
    }).then((response)=>{
    setResult({
      school: formData?.school,
      list: response,
    });
  }).catch((e)=>{

  })

  };

  const RegistrationResult = () => {
    return (
      <>
        {result?.school ? <h4>List of students from {result?.school}</h4> : ""}
        <Table striped bordered hover size="sm">
          <thead style={{ textAlign: "center" }}>
            <tr>
              <th>#</th>
              <th>Username</th>
              <th>School ID</th>
              <th>Student PIN</th>
              <th>Created On</th>
            </tr>
          </thead>
          <tbody style={{ verticalAlign: "middle", textAlign: "center" }}>
            {result.list.map((student, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{student?.username}</td>
                  <td>{student?.schoolPin}</td>
                  <td>{student?.pin}</td>
                  <td>{(new Date(student?.createdAt)).toLocaleDateString()}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  };

  const SubmitButton = ({ label }) => {
    return (
      <button type="submit" className="btn btn-primary" disabled={btnDisabled}>
        {label}
      </button>
    );
  };

  return (
    <>
      {view === Views.ListOfSchool && <SchoolList />}
      {view === Views.ListOfStudents && <StudentList />}
      {view === Views.SchoolForm && (
        <div className={styles.SchoolRegistrationForm}>
          {result.list.length > 0 && <RegistrationResult />}
          {result.list.length === 0 && (
            <>
              <div className={styles.Title}>
                School Registration with No. of Students
              </div>

              <Form style={{}} onSubmit={handleSubmit}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalSchool"
                >
                  <Form.Label column sm={3}>
                    School
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={handleChange}
                      name="school"
                      required={true}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalNoOfStudents"
                >
                  <Form.Label column sm={3}>
                    Language Code
                  </Form.Label>
                  <Col sm={5}>
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="language"
                      onChange={handleChange}
                      required={true}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalSchool"
                >
                  <Form.Label column sm={3}>
                    Teacher
                    (Email)
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="email"
                      placeholder=""
                      onChange={handleChange}
                      name="email"
                      required={true}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalSchool"
                >
                  <Form.Label column sm={3}>
                    Password
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={handleChange}
                      name="password"
                      required={true}
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalNoOfStudents"
                >
                  <Form.Label column sm={3}>
                    No. of Students
                  </Form.Label>
                  <Col sm={5}>
                    <Form.Control
                      type="number"
                      placeholder=""
                      name="students"
                      min={1}
                      max={1000}
                      onChange={handleChange}
                      required={true}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Col sm={{ span: 15, offset: 3 }}>
                    <SubmitButton label={"Create School"} />
                    &nbsp;
                    <button
                      onClick={() => {
                        setView(Views.ListOfSchool);
                      }}
                      className="btn btn-primary"
                      type="button"
                      style={{ color: "white" }}
                    >
                      Cancel
                    </button>
                  </Col>
                </Form.Group>
              </Form>
            </>
          )}
        </div>
      )}
    </>
  );
};

ModerateSchool.propTypes = {};

ModerateSchool.defaultProps = {};

export default ModerateSchool;
