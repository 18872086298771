import { URLS } from "../constants/config"

class SchoolService {
    constructor(httpClient) {
        this.httpClient = httpClient
    }
    async listSchool() {
        try {
            const url = URLS.INTERNAL.SCHOOLS_LISTING
            return await this.httpClient.get(url)            
        } catch (error) {
            console.error(error)
            return false
        }
    }
    sortASC(data){
        return data.sort((p1, p2) => (p1.name > p2.name) ? 1 : (p1.name < p2.name) ? -1 : 0)
    }
}
export default SchoolService;