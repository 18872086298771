import { URLS } from "../constants/config"

class ExamService {
    constructor(httpClient) {
        this.httpClient = httpClient
    }
    async deleteExam(examUuid) {
        try {
            const url = URLS.INTERNAL.DELETE_EXAM
            const body = { "examUuid": examUuid }
            await this.httpClient.delete(url, body)
            return {}
        } catch (error) {
            console.error(error)
            return false
        }
    }
    async getExam(){
        try {
            const url = URLS.INTERNAL.GET_EXAM
            const body = {}            
            return await this.httpClient.post(url, body)
        } catch (error) {
            console.error(error)
            return false
        }
    }
    async getRecordings(url){
        try {
            return await this.httpClient.get(url)
        } catch (error) {
            console.error(error)
            return false
        }
    }
    async createExam(student){
        try {
            const url = URLS.INTERNAL.POST_EXAM
            const body = { studentUuid: student?.userUuid }            
            return await this.httpClient.post(url, body)
        } catch (error) {
            console.error(error)            
        }
    }
    async getTeacherListing() {
        try {
            let url = URLS.INTERNAL.TEACHERS_LISTING
            const response = await this.httpClient.get(url)
            return response.json()
        } catch (error) {
            console.error(error)
        }
    }    
    async getListOfTeachers(schoolUUID) {
        try {
            let url = URLS.INTERNAL.LIST_OF_TEACHERS(schoolUUID)
            const response = await this.httpClient.get(url)
            return response.json()
        } catch (error) {
            console.error(error)
        }
    }    
    async generateDataset() {
        try {
            let url = URLS.INTERNAL.GENERATE_DATASET
            const response = await this.httpClient.get(url)
            return response.json()
        } catch (error) {
            console.error(error)
        }
    }    
    async getListOfSchools() {
        try {
            let url = URLS.INTERNAL.SCHOOLS_LISTING
            const response = await this.httpClient.get(url)
            return response.json()
        } catch (error) {
            console.error(error)
        }
    }    
    async getListOfStudents(schoolUUID) {
        try {
            let url = URLS.INTERNAL.LIST_OF_STUDENTS(schoolUUID)
            const response = await this.httpClient.get(url)
            return response.json()
        } catch (error) {
            console.error(error)
        }
    }    
    async getScores(examUuid) {
        try {
            let url = URLS.INTERNAL.SCORES_LISTING
            const response = await this.httpClient.post(url,{examUuid:examUuid})
            return response.json()
        } catch (error) {
            console.error(error)
        }
    }    
    async getDatasetStat(examUuid) {
        try {
            let url = URLS.INTERNAL.GET_DATASET_STAT_FOR_SCORING(examUuid)
            const response = await this.httpClient.get(url)
            return await response.json()
        } catch (error) {
            console.error(error)
        }
    }    
    async getAudioForScore(datasetAudioUuid) {
        try {
            let url = URLS.INTERNAL.GET_AUDIO_FOR_SCORING(datasetAudioUuid)
            const response = await this.httpClient.get(url)
            return response.blob()
        } catch (error) {
            console.error(error)
        }
    }    

    async updateScore(payload) {
        try {
            let url = URLS.INTERNAL.SCORE_UPDATE
            const response = await this.httpClient.post(url,{
                datasetAudioUuid:payload.datasetAudioUuid,
                humanScore : payload.score,
                transcription : payload.transcription,
                isClear:payload.isClear
            })
            return response.json()
        } catch (error) {
            console.error(error)
        }
    }    
}
export default ExamService