import { useState } from "react";
import { URLS } from "../constants/config";
import NavBar from "../components/NavBar/NavBar";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import { Services } from "../services/index";
import Pagination from '../components/Pagination/Pagination';


function handleS3Key(key) {
  document.getElementById("audio-button-"+key).disabled = true
  fetch(URLS.INTERNAL.DOWNLOAD_S3_FILE, {
    headers: {
      "Content-Type": "application/json",
      responseType: 'blob'
    },
    method: "POST",
    //"merged-1700746643369.wav"
    body: JSON.stringify({
      "file_name": key
    }),
  }).then((response) => {
    if (response.ok) {
    response.blob().then((b)=>{
      let e = document.getElementById("audio-" + key);
      document.getElementById("audio-button-" + key).style.display = "none";
      const a = document.createElement("audio");
      a.src = URL.createObjectURL(b);
      a.controls = true;
      e.append(a);
    })
    }
  });
}

export const ListRecordings = () => {
  const [listItems, setListItems] = useState([]);
  const [header, setHeader] = useState({});
  const [timestamp,setTimeStamp] = useState(null)
  const navigate = useNavigate();
  let user = JSON.parse(localStorage.getItem("user"));
  let token = user?.accessToken;
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageRef = useRef(1)
  const [selectedSchool, setSelectedSchool] = useState("")
  const [schools,setSchools] = useState([])
  const Populate = () => {
    if (Services.User.isModerator()) {
      Services.School.listSchool().then(async (response) => {
        if (response.ok) {
          const schoolData = await (await response.json())
          setSchools(Services.School.sortASC(schoolData))
          if(schoolData.length>0)
          Services.Exam.getRecordings(URLS.INTERNAL.TEACHERS_LISTING+`?schoolUuid=${schoolData[0]?.schoolUuid}&page=`+currentPage).
          then(async (response)=>{
            if(response.ok){
              const data = await (await response.json())              
              setTotalPages(data.no_of_pages)
              setCurrentPage(data.currentPage)
              currentPageRef.current = data.currentPage
              setHeader({...header,line1: 'R.E.A.L student scorecard: '+schoolData[0]?.name})
              setListItems(FormatListData(data));
            }
          })
        }
      }).catch((e) => {
        console.log("list-school", e)
      })
    }
    if (Services.User.isTeacher()) {
      setSchools([{schoolUuid:Services.User.getLocalStorageDetails()?.schoolUuid,name:Services.User.getLocalStorageDetails()?.schoolName}])      
      Services.Exam.getRecordings(URLS.INTERNAL.TEACHERS_LISTING+`?schoolUuid=${Services.User.getLocalStorageDetails()?.schoolUuid}&page=`+currentPage).
      then(async (response)=>{
        if(response.ok){
          const data = await (await response.json())
          setTotalPages(data.no_of_pages)
          setCurrentPage(data.currentPage)
          currentPageRef.current = data.currentPage
          setHeader({...header,line1: 'R.E.A.L student scorecard: '+Services.User.getLocalStorageDetails()?.schoolName})
          setListItems(FormatListData(data));
        }
      })
    }
    if (!token) navigate("/");
  }

  const FormatListData = (data) => {
    let list = data?.response?.map((item) => {
      if (!item?.exams) return [];
      return item?.exams.map((a) => {
        return {
          ...a,
          date: a.examTime,
          studentName: item?.studentName,
          schoolName: item?.schoolName,
          studentUuid: item?.studentUuid
        };
      });
    });
    let l = list.flat();
    return l.sort((a, b) => {
      let da = new Date(a.date),
        db = new Date(b.date);
      return db - da;
    });
  }

  useEffect(Populate, []);

  const handleDelete = (e)=> {
    setListItems([])
    Services.Exam
      .deleteExam(e.target.value)
      .then(() => { Populate() })
  }

  const RecordingTable = (props) => {
    const { listItems } = props;

    const ActionAudio = (props) =>{
      const { s3key } = props

      if(!s3key)
      return""

      return(
        <>
        <div id={"audio-" + s3key}></div>
          <div style={{ width: "8em" }}>
          <button
            style={{ marginBottom: "5px" }}
            className="btn btn-outline-primary btn-sm"
            data={s3key}
            id={"audio-button-" + s3key}
            onClick={(e) => {
              handleS3Key(s3key, e);
            }}
          >
            Get Audio
          </button>
        </div>
      </>
      )
    }
    const headings = [
      "Listening/Vocabulary",
      "Phonemic Awareness",
      "Letter-Sound",
      "Non-words",
      "Familiar Words",
      "Reading Fluency",
      "Comprehension",
    ];

    const handlePageChange = (pageNumber,selected) => {
      setListItems([])
      setTotalPages(0)
      setCurrentPage(1)
      if (pageNumber < 1 || pageNumber > totalPages) return;      
      setCurrentPage(pageNumber);      
      Services.Exam.getRecordings(URLS.INTERNAL.TEACHERS_LISTING+`?schoolUuid=${selected || document.getElementById("schoolUuid").value}&page=`+pageNumber).
      then(async (response)=>{
        if(response.ok){
          const data = await (await response.json())
          currentPageRef.current = pageNumber
          setTotalPages(data.no_of_pages)
          setCurrentPage(data.currentPage)
          setHeader({...header,line1: 'R.E.A.L student scorecard: '+schools.find((x)=>{return x?.schoolUuid===document.getElementById("schoolUuid").value})?.name})
          setListItems(FormatListData(data));
        }
      })
    };

    return (
      <>

        <form>
          <div className="row mb-3">
            <label 
            style={{color:"white"}}              
            className="col-sm-2 col-form-label">School:
            </label>
            
            <div className="col-sm-4">
            <select onChange={(e) => {
                  console.log(e.target.value)
                  setSelectedSchool(e.target.value)
                  handlePageChange(1,e.target.value)
                }} id="schoolUuid" value={selectedSchool}
                  name="schoolUuid"
                  className="form-select">
                  {schools.map(({ schoolUuid, name }) => (
                    <option key={schoolUuid} value={schoolUuid}
                    >
                      {name}
                    </option>
                  ))}
                </select>              
              {/* {!Services.User.isModerator() ?
                <input type="email" readOnly class="form-control" id="inputEmail3" value={Services.User.getLocalStorageDetails()?.schoolName} />
                : <select onChange={(e) => {
                  console.log(e.target.value)
                  setSelectedSchool(e.target.value)
                  handlePageChange(1)
                }} id="schoolUuid" value={selectedSchool}
                  name="schoolUuid"
                  className="form-select">
                  {schools.map(({ schoolUuid, name }) => (
                    <option key={schoolUuid} value={schoolUuid}
                    >
                      {name}
                    </option>
                  ))}
                </select>} */}
            </div>
          </div>
        </form>

      <table className="table table-bordered border-primary table-sm" style={{width:"50%"}}>
        <thead style={{verticalAlign:"middle"}}>
        <tr key="key-head-0">
            <th colSpan="20">
              <span style={{ textAlign: "center", color: "black",fontSize:"2em" }}>{header?.line1}</span>
            </th>
          </tr>
          <tr key="key-head-1">
            <th>SN</th>
            <th>Date/Time</th>
            <th>Student Name</th>
            <th>School</th>
            <th>CLICK TO SCORE</th>
            <th>{headings[0]}</th>
            <th>{headings[1]}</th>
            <th>{headings[2]}</th>
            <th>{headings[3]}</th>
            <th>{headings[4]}</th>
            <th>{headings[5]}</th>
            <th>{headings[6]}</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody style={{ verticalAlign: "middle" }}>
        {listItems.map(({date,studentName,schoolName,examUuid,
        section1audio,section1audioMarkers,  
        section2audio,section2audioMarkers,  
        section3audio,section3audioMarkers,  
        section4audio,section4audioMarkers,  
        section5audio,section5audioMarkers,  
        section6audio,section6audioMarkers,  
        section7audio,section7audioMarkers,  
        studentUuid,
        section1grading,section1score,
        section2grading,section2score,
        section3grading,section3score,
        section4grading,section4score,
        section5grading,section5score,
        section6grading,section6score,
        section7grading,section7score
      
      },index) => {
        
        return (
          <tr key={index}>
            <td>{(index+1)+((currentPage-1)*25)}</td>
            <td>{new Date(date).toLocaleString()}</td>
            <td>{studentName}</td>
            <td>{schoolName}</td>
            <td>
              <button
                type="button"
                value={studentUuid}
                style={{ marginTop: "5px" }}
                onClick={(e) => {
                  navigate("/scores/" + examUuid);
                }}
                className="btn btn-sm btn-outline-primary"
              >
                Score
              </button>
            </td>
            <td>
              <ActionAudio
                s3key={section1audio}
                marker={section1audioMarkers}
              />
              <Score data={{ score: section1score, grade: section1grading }} />
            </td>
            <td>
              <ActionAudio
                s3key={section7audio}
                marker={section7audioMarkers}
              />
              <Score data={{ score: section7score, grade: section7grading }} />
            </td>
            <td>
              <ActionAudio
                s3key={section2audio}
                marker={section2audioMarkers}
              />
              <Score data={{ score: section2score, grade: section2grading }} />
            </td>
            <td>
              <ActionAudio
                s3key={section3audio}
                marker={section3audioMarkers}
              />
              <Score data={{ score: section3score, grade: section3grading }} />
            </td>
            <td>
              <ActionAudio
                s3key={section4audio}
                marker={section4audioMarkers}
              />
              <Score data={{ score: section4score, grade: section4grading }} />
            </td>
            <td>
              <ActionAudio
                s3key={section5audio}
                marker={section5audioMarkers}
              />
              <Score data={{ score: section5score, grade: section5grading }} />
            </td>
            <td>
              <ActionAudio
                s3key={section6audio}
                marker={section6audioMarkers}
              />
              <Score data={{ score: section6score, grade: section6grading }} />
            </td>
            <td>
              {Services.User.isModerator() === true ||
              Services.User.getLocalStorageDetails().name ===
                "Test School teacher" ? (
                <button
                  type="button"
                  value={examUuid}
                  onClick={handleDelete}
                  className="btn btn-sm btn-outline-primary"
                >
                  Remove
                </button>
              ) : (
                ""
              )}
            </td>
          </tr>
        );
      })}
        </tbody>
      </table>
      <div style={{justifyContent : "center", alignContent : "center", display : "flex"}}>
        
        <Pagination
          currentPage={currentPageRef.current}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
      </>
    );
  };

  const Score = (props)=>{    
    if(props.data.score){
      return  props.data.score + " / "+ props.data.grade
    }
    return ""
  }

  return (
    <>
      <NavBar />

      <RecordingTable listItems={listItems} />
      <div
        className="modal fade"
        id="exampleModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Audio Timestamp
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ textAlign: "left" }}>
              {timestamp}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
