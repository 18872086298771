import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Assessment = ({ showAssessment, handleCloseAssessment, assessment }) => {
  //const data = require("./sample-success.json")
  const data = assessment;
  //const data = null;
  const NBest = data? data.NBest[0]: null
  const summary = NBest?.PronunciationAssessment
  const words = NBest?.Words
  const Summary = (props) => {
    if (!props.data) return
    const { AccuracyScore, FluencyScore, CompletenessScore, PronScore } = props.data
    return (
      <>
        <h5>DisplayText</h5>
        <p>{data?.DisplayText}</p>
        <br />
        <h5>Summary</h5>
        <table className="table table-bordered">
          <thead style={{ textAlign: 'center' }}>
            <tr>
              <th>Accuracy Score</th>
              <th>Fluency Score</th>
              <th>Completeness Score</th>
              <th>Pron Score</th>
              <th>Duration</th>
              <th>Confidence</th>
              <th>Recognition Status</th>
              <th>SNR</th>
            </tr>
          </thead>
          <tbody style={{ textAlign: 'center' }}>
            <tr>
              <td>{AccuracyScore}</td>
              <td>{FluencyScore}</td>
              <td>{CompletenessScore}</td>
              <td>{PronScore}</td>
              <td>{data?.Duration/1000}</td>
              <td>{Math.round((NBest?.Confidence + Number.EPSILON) * 100) / 100}</td>
              <td>{data?.RecognitionStatus}</td>
              <td>{Math.round((data?.SNR + Number.EPSILON) * 100) / 100}</td>
            </tr>
          </tbody>
        </table></>
    )
  }

  const WordsAssessment = (props) => {
    //console.log(props.data)
    const words = props.data
    return (
      <>
        <br />
        <h5>Words Assessment</h5>
        <table className="table table-bordered" style={{ tableLayout: "auto" }}>
          <thead style={{ textAlign: 'center' }}>
            <tr>
              <th>Word</th>
              <th>Overall</th>
              <th>ErrorType</th>
              <th>Syllables</th>
              <th>Phonemes</th>
            </tr>
          </thead>
          <tbody>
            {
              words.map((w) => {
                return (
                  <tr>
                    <td style={{ paddingLeft: '1.5em', verticalAlign: 'middle' }}>{w?.Word}</td>
                    <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      {w?.PronunciationAssessment?.AccuracyScore}
                    </td>
                    <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      {w?.PronunciationAssessment?.ErrorType}
                    </td>
                    <td style={{ width: '0%', textAlign: 'center', verticalAlign: 'middle' }}>
                      <table className="table table-bordered" style={{ border: '1px solid black' }}>
                        <thead>
                          <tr>
                            <th>Syllable</th>
                            <th>Grapheme</th>
                            <th>Score</th>
                            <th>Duration</th>
                            <th>Offset</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            w?.Syllables && w?.Syllables.map((s) => {
                              return <tr>
                                <td style={{ textAlign: 'left', paddingLeft: '1.2em' }}>
                                  {s?.Syllable}
                                </td>                                <td style={{ textAlign: 'left', paddingLeft: '1.2em' }}>
                                  {s?.Grapheme}
                                </td>
                                <td>
                                  {s?.PronunciationAssessment?.AccuracyScore}
                                </td>
                                <td>
                                  {s?.Duration/1000}
                                </td>
                                <td>
                                  {s?.Offset/1000}
                                </td>
                              </tr>
                            })

                          }
                        </tbody>
                      </table>
                    </td>
                    <td style={{ width: '0%', textAlign: 'center' }}>
                      <table className="table table-bordered" style={{ border: '1px solid black' }}>
                        <thead>
                          <tr>
                            <th>Phoneme</th>
                            <th>Score</th>
                            <th>Duration</th>
                            <th>Offset</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            w?.Phonemes && w?.Phonemes.map((s) => {
                              return <tr>
                                <td style={{ textAlign: 'left', paddingLeft: '1.2em' }}>
                                  {s?.Phoneme}
                                </td>
                                <td>
                                  {s?.PronunciationAssessment?.AccuracyScore}
                                </td>
                                <td>
                                  {s?.Duration/1000}
                                </td>
                                <td>
                                  {s?.Offset/1000}
                                </td>
                              </tr>
                            })
                          }
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table></>
    )
  }
  return (
    <Modal show={showAssessment} onHide={handleCloseAssessment} className={data?'modal-xl':'modal-sm'}>
      <Modal.Header closeButton>
        <Modal.Title>Assessment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          assessment ? <><Summary data={summary} />
            <WordsAssessment data={words} /></> : 'Assessment Unavailable'
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseAssessment}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
Assessment.propTypes = {};

Assessment.defaultProps = {};

export default Assessment;
